<template>
  <v-app>
    <OrganisationDetails />
    <OrganisationStatement />
    <div class="d-flex">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Filter"
        dense
        clearable
      ></v-text-field>

      <OrganisationsForm />

      <div>
        <es-btn @click="refresh" color="indigo"
          ><fa icon="sync" /> Refresh</es-btn
        >
        <es-btn @click="diffToggleDialog" color="orange"
          ><fa icon="magic" /> Check Org names</es-btn
        >
      </div>
    </div>
    <v-card class="mb-6">
      <v-data-table
        fixed-header
        height="70vh"
        sort-by="name"
        loading-text="Loading organisations ..."
        no-data-text="No organisations found."
        :must-sort="true"
        :search="search"
        :headers="headers"
        :items="items"
        :items-per-page="100"
        :loading="busy"
        :header-props="{ sortIcon: 'mdi-menu-down' }"
        :footer-props="{ 'disable-items-per-page': true }"
      >
        <div v-show="busy" slot="progress" class="dot-windmill"></div>

        <template v-slot:[`item.name`]="{ item }">
          <a
            v-if="item.hubspot_id"
            target="_blank"
            :href="
              `https://app.hubspot.com/contacts/5907605/company/${item.hubspot_id}`
            "
            :title="`Hubspot Company ID: ${item.hubspot_id}`"
            >{{ item.name }}</a
          >
          <span v-else>{{ item.name }}</span>
        </template>
        <template v-slot:[`item.initial_dashboard_user`]="{ item }">
          <div class="d-flex">
            <es-btn
              :title="
                item.initial_dashboard_user.active
                  ? `Impersonate ${item.initial_dashboard_user.username} in the B2B Dashboard`
                  : 'This user is not active'
              "
              class="es-btn mr-2"
              @click.stop="
                impersonate(item.initial_dashboard_user.user_profile_id)
              "
              :disabled="
                busy ||
                  !item.initial_dashboard_user.user_profile_id ||
                  !item.initial_dashboard_user.active
              "
              ><fa icon="user-alt"
            /></es-btn>
            <div>
              {{ item.initial_dashboard_user.username }}<br />
              <small>{{ item.initial_dashboard_user.email }}</small>
            </div>
          </div>
        </template>
        <template v-slot:[`item.most_recently_created_connect_code`]="{ item }">
          {{ item.formatted_most_recently_created_connect_code }}
        </template>
        <template v-slot:[`item.most_recently_used_connect_code`]="{ item }">
          {{ item.formatted_most_recently_used_connect_code }}
        </template>
        <template v-slot:[`item.most_recent_certificate_purchased`]="{ item }">
          {{ item.formatted_most_recent_certificate_purchased }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions d-flex">
            <es-btn
              :title="`View statement for ${item.name}`"
              class="es-btn mb-1 ml-auto white--text"
              color="secondary"
              @click.stop="viewStatement(item.organisation_id)"
              :disabled="busy"
              ><fa icon="clipboard-list"
            /></es-btn>
            <es-btn
              :title="`View details for ${item.name}`"
              class="es-btn white--text"
              color="secondary"
              @click.stop="open(item.organisation_id)"
              :disabled="busy"
              ><fa icon="dice-d6" /> Details</es-btn
            >
            <es-btn
              :title="`Copy Organisation ID to clipboard`"
              class="es-btn"
              color="primary"
              @click="
                copyToClipboard({
                  label: 'Organisation ID',
                  text: item.organisation_id
                })
              "
              ><fa icon="copy"
            /></es-btn>
            <es-btn
              :title="`Copy HubSpot ID to clipboard`"
              class="es-btn"
              color="orange"
              @click="
                copyToClipboard({ label: 'HubSpot ID', text: item.hubspot_id })
              "
              ><fa icon="copy"
            /></es-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="diffDialogActive">
      <v-card title="Organisation name differences" class="pa-2">
        <v-card-actions>
          <h4 class="ma-0">Organisations with different names in HubSpot</h4>
          <v-spacer></v-spacer>
          <v-btn
            class="close-button"
            fab
            dark
            x-small
            depressed
            color="grey"
            @click="diffToggleDialog"
            ><v-icon dark>mdi-close</v-icon></v-btn
          >
        </v-card-actions>
        <v-data-table
          dense
          :items-per-page="100"
          :footer-props="{ 'disable-items-per-page': true }"
          sort-by="code"
          :must-sort="true"
          fixed-header
          height="70vh"
          :headers="diffHeaders"
          :items="diffItems"
          :header-props="{
            sortIcon: 'mdi-menu-down'
          }"
          :loading="diffBusy"
          :sticky-footer="true"
          no-data-text="No found."
        >
          <template v-slot:[`item.actions`]="{ item }">
            <es-btn
              :title="`View details for ${item.name}`"
              class="es-btn white--text"
              color="secondary"
              @click.stop="diffUpdateItem(item)"
              :class="{ 'opacity-50': item.isBusy }"
              :disabled="item.isBusy"
              ><fa icon="dice-d6" /> Update name</es-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import * as clipboardy from "clipboardy";
import OrganisationsForm from "@/components/organisations/OrganisationForm.vue";
import OrganisationDetails from "@/components/organisations/OrganisationDetails.vue";
import OrganisationStatement from "@/components/organisations/OrganisationStatement.vue";

export default {
  components: {
    OrganisationsForm,
    OrganisationDetails,
    OrganisationStatement
  },

  data: () => ({
    search: ""
  }),

  computed: {
    ...mapState("organisation", ["headers", "items", "busy"]),
    ...mapGetters({
      diffItems: "orgHubSpot/items",
      diffBusy: "orgHubSpot/busy",
      diffError: "orgHubSpot/error",
      diffHeaders: "orgHubSpot/headers",
      _diffDialogActive: "orgHubSpot/dialogActive"
    }),
    diffDialogActive: {
      get() {
        return this._diffDialogActive;
      },
      set(value) {
        this.diffToggleDialog();
      }
    }
  },
  methods: {
    ...mapActions("organisation", [
      "loadOrganisation",
      "loadOrganisationStatement"
    ]),
    ...mapActions({
      diffToggleDialog: "orgHubSpot/toggleDialog",
      diffUpdateItem: "orgHubSpot/updateItem"
    }),
    async copyToClipboard({ label, text }) {
      await clipboardy.write(text);
      this.$store.dispatch("snackbar/snack", {
        mode: "success",
        message: `✅ Copied ${label} to clipboard: <strong class="px-4">${text}</strong>`
      });
    },
    refresh() {
      this.$store.dispatch("organisation/fetchOrganisations");
    },
    open(organisation_id) {
      // this.loadOrganisation(organisation_id);
      this.$router.push({
        name: "Organisations",
        params: { uuid: organisation_id },
        query: this.$route.query
      });
    },
    viewStatement(organisation_id) {
      this.$router.push({
        name: "Organisations-Statement",
        params: { uuid: organisation_id },
        query: this.$route.query
      });
    },
    impersonate(user_profile_id) {
      const baseUrl =
        process.env.VUE_APP_DASHBOARD_BASE_URL ||
        "https://dashboard.englishscore.com";
      const randomParam = Math.floor(Math.random() * 100000);
      const href = `${baseUrl}/?impersonate_user_id=${user_profile_id}&rand=${randomParam}`;
      window.open(href);
    }
  },
  watch: {
    search(value) {
      if (this.$route.query.search === value) return;

      const query = { ...this.$route.query };
      if (!value) delete query.search;
      else query.search = value;
      this.$router.push({
        name: "Organisations",
        params: this.$route.params,
        query
      });
    },
    $route: {
      handler() {
        this.search = this.$route.query.search;
      },
      immediate: true
    }
  },
  mounted() {
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>
.dot-windmill {
  position: absolute;
  top: 84px;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #220a5a;
  color: #220a5a;
  transform-origin: 5px 15px;
  -webkit-animation: dotWindmill 2s infinite linear;
  animation: dotWindmill 2s infinite linear;
}

.actions {
  text-align: right;
  margin-right: -20px;
}

.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
  margin-bottom: 2px;
  white-space: nowrap;
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit;
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
  .v-data-footer__select {
    visibility: hidden;
  }
}
.opacity-50 {
  opacity: 0.5;
}
</style>
