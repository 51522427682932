<template>
  <section>
    <es-btn color="teal" @click="navigateToAddNew"><fa icon="plus" /> Add New</es-btn>
    <v-dialog v-model="dialog" max-width="900px">
      <v-card class="new-form">
        <h1>Add New Organisation</h1>
        <v-card-text>
          <div class="submit-success"><p v-if="message">{{ message }}</p></div>
          <div class="submit-errors"><p v-if="error">{{ error.message }}</p></div>
          <validation-observer ref="form" v-slot="{ invalid }">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col">
                  <validation-provider name="HubspotID" v-slot="{ errors }" mode="passive">
                    <div :class="{ 'error': errors.length }">
                      <label for="code">Hubspot ID <fa icon="asterisk" /></label>
                      <input
                        type="text"
                        placeholder="Enter Hubspot company ID"
                        v-model="hubspotCompanyId"
                        @keyup="loadHubspotCompanyName"
                        @blur="checkIfHubspotCompanyNameIsEmpty"
                      />
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                  <div class="mt-2">
                    Hubspot Company Name: {{ hubspotCompanyName }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <validation-provider rules="required|email" name="Email" v-slot="{ errors }">
                    <div :class="{ 'error': errors.length }">
                      <label for="code">Email<fa icon="asterisk" /></label>
                      <input type="text" placeholder="Enter admin email" v-model="adminEmail" />
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col actions">
                  <v-progress-circular v-if="busy" indeterminate size="28" color="amber"></v-progress-circular>
                  <button :disabled="busy || invalid || !hubspotCompanyId.length" class="es-btn teal px-16">Submit</button>
                  <span class="es-btn" @click="close">Cancel</span>
                </div>
              </div>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data: function () {
    return {
      error: false,
      message: false,
      dialog: false,
      hubspotCompanyId: "",
      adminEmail: "",
    };
  },
  computed: {
    ...mapState("organisation", ["busy", "hubspotCompanyName"]),
  },
  methods: {
    ...mapActions("organisation", [
      "addOrganisation",
      "loadHubspotCompanyName",
    ]),
    navigateToAddNew: function () {
      this.$router.push({
        name: "Organisations",
        params: { uuid: "new" },
        query: this.$route.query,
      });
    },
    close() {
      this.dialog = false;
    },
    onSubmit() {
      const payload = {
        hubspot_company_id: this.hubspotCompanyId,
        admin_email: this.adminEmail,
      };
      this.addOrganisation(payload);
      this.close();
      return false;
    },
    checkIfHubspotCompanyNameIsEmpty() {
      if (this.hubspotCompanyId.trim() === "") {
        this.$refs.form.setErrors({
          HubspotID: ["This field is required"],
        });
      }
    },
  },
  watch: {
    dialog(showingForm) {
      this.$store.commit("organisation/setHubspotCompanyName", "");
      this.hubspotCompanyId = "";
      this.adminEmail = "";
      if (this.$refs.form) {
        this.$refs.form.setErrors({
          HubspotID: [],
        });
      }

      if (showingForm) return;
      if (this.$route.params.uuid) {
        this.$router.push({
          name: "Organisations",
          params: {},
          query: this.$route.query,
        });
      }
    },
    hubspotCompanyName() {
      // https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html
      // https://vee-validate.logaretm.com/v3/advanced/server-side-validation.html#setting-errors-manually
      const validationErrors = [];
      if (this.hubspotCompanyName.includes("not found")) {
        validationErrors.push("Hubspot company not found");
      }
      else if (this.hubspotCompanyId.trim() === "") {
        validationErrors.push("This field is required");
      }
      this.$refs.form.setErrors({
        HubspotID: validationErrors,
      });
    },
    $route: {
      handler() {
        this.dialog = this.$route.params.uuid === "new";
      },
      immediate: true,
    },
  },
};
</script>

<style lang='scss' scoped>
.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
}

h1 {
  text-align: center;
}

label {
  display: inline-block;
  font-size: 12px;
  cursor: pointer;

  & svg {
    color: $cranberry;
    width: 8px;
    vertical-align: top;
    margin-left: 0.25em;
  }
}

input,
select {
  @include input;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.new-form {
  padding: 2em;
}
</style>
