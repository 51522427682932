<template>
  <v-dialog v-model="dialog">
    <v-banner sticky color="white">
      <v-btn
        class="close-button"
        fab
        dark
        x-small
        depressed
        color="grey"
        @click="close"
        title="Cancel"
        ><v-icon dark>mdi-close</v-icon></v-btn
      >
      <v-card-text class="pa-0">
        <h1 class="mb-0">
           <a
            v-if="organisation.hubspot_id"
            target="_blank"
            :href="`https://app.hubspot.com/contacts/5907605/company/${organisation.hubspot_id}`"
            :title="`Hubspot Company ID: ${organisation.hubspot_id}`"
          >{{ organisation.name }}</a>
          <span v-else>{{ organisation.name }}</span>
        </h1>

      </v-card-text>
    </v-banner>
    <v-card class="py-8" id="organisation-details">
      <v-card-text >
        <v-card class="pa-4 mb-8">
          <h4 class="mt-2"><fa icon="user-alt" /> B2B Dashboard Owners</h4>
          <v-data-table
            dense
            :items-per-page="100"
            :footer-props="{ 'disable-items-per-page': true }"
            sort-by="name"
            :must-sort="true"
            :headers="organisation.users.headers"
            :items="organisation.users.items"
            :header-props="{
              sortIcon: 'mdi-menu-down',
            }"
            no-data-text="No B2B Dashboard Owners found."
          >
            <template v-slot:[`item.created`]="{ item }">
              {{ item.formatted_created }}
            </template>
            <template v-slot:[`item.renewal_date`]="{ item }">
              {{ item.formatted_renewal_date }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <es-btn :disabled="busy" @click="changeAdminAccountStatus(item)" class="small" :color="item.active ? 'orange' : 'indigo'" >
              <span class="text-xs">{{ `${item.active ? 'Deactivate' : 'Reactivate'} account` }}</span></es-btn>
            </template>
          </v-data-table>
        </v-card>

        <v-card class="pa-4 mb-8">
          <h4 class="mt-2"><fa icon="ticket-alt" /> Connect codes</h4>
          <v-data-table
            dense
            :items-per-page="100"
            :footer-props="{ 'disable-items-per-page': true }"
            sort-by="code"
            :must-sort="true"
            :headers="organisation.connectCodes.headers"
            :items="organisation.connectCodes.items"
            :header-props="{
              sortIcon: 'mdi-menu-down',
            }"
            no-data-text="No connect codes found."
          >
            <template v-slot:[`item.validFrom`]="{ item }">
              {{ item.formattedValidFrom }}
            </template>
            <template v-slot:[`item.validTo`]="{ item }">
              {{ item.formattedValidTo }}
            </template>
          </v-data-table>
        </v-card>

        <v-card class="pa-4 mb-8">
          <h4 class="mt-2"><fa icon="ticket-alt" /> Certificate vouchers</h4>
          <v-data-table
            dense
            :items-per-page="100"
            :footer-props="{ 'disable-items-per-page': true }"
            sort-by="code"
            :must-sort="true"
            :headers="organisation.certificateVouchers.headers"
            :items="organisation.certificateVouchers.items"
            :header-props="{
              sortIcon: 'mdi-menu-down',
            }"
            no-data-text="No certificate vouchers found."
          >
            <template v-slot:[`item.validFrom`]="{ item }">
              {{ item.formattedValidFrom }}
            </template>
            <template v-slot:[`item.validTo`]="{ item }">
              {{ item.formattedValidTo }}
            </template>
          </v-data-table>
        </v-card>

        <v-card class="pa-4 mb-8">
          <h4 class="mt-2"><fa icon="ticket-alt" /> Certificate credits</h4>
          <v-data-table
            dense
            :items-per-page="100"
            :footer-props="{ 'disable-items-per-page': true }"
            sort-by="code"
            :must-sort="true"
            :headers="organisation.certificateCredits.headers"
            :items="organisation.certificateCredits.items"
            :header-props="{
              sortIcon: 'mdi-menu-down',
            }"
            no-data-text="No certificate credits found."
          >
            <template v-slot:[`item.validFrom`]="{ item }">
              {{ item.formattedValidFrom }}
            </template>
            <template v-slot:[`item.validTo`]="{ item }">
              {{ item.formattedValidTo }}
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState("organisation", ["showOrganisationModal", "organisation", "busy"]),
    dialog: {
      get() { return this.showOrganisationModal },
      set(value) { this.$store.commit("organisation/setShowOrganisationModal", value) },
    },
  },
  methods: {
    ...mapActions("organisation", ["manageOrgAdminAccountStatus"]),
    close() {
      this.dialog = false;
    },
    changeAdminAccountStatus(user) {
      this.manageOrgAdminAccountStatus({
        email: user.email,
        action: !user.active,
        organisationId: this.$route.params.uuid
      });
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$store.commit("organisation/setShowOrganisationModal", false);
        this.$store.dispatch("organisation/resetOrganisationState");
        if (this.$route.params.uuid) this.$router.push({ name: "Organisations", params: {}, query: this.$route.query })
      }
    },
    $route: {
      handler() {
        if (this.$route.name === 'Organisations' && this.$route.params.uuid) {
          this.$store.dispatch("organisation/loadOrganisation", this.$route.params.uuid);
        }
        else {
          this.close()
        }
      },
      immediate: true
    }
  },
};
</script>

<style lang='scss' scoped>
h1 {
  text-align: center;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 22px;
  z-index: 1;
}

::v-deep {
  #organisation-details {
    .v-data-table-header th {
      text-align: left;
      color: var(--dark-indigo) !important;
      background-color: white !important;
      &:first-child {
        border-top-left-radius: 4px;
        padding-left: 0;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }
      &.active,
      & .v-data-table-header__icon {
        color: var(--purple-blue);
      }
      &.sortable {
        white-space: nowrap;
      }
    }
    .v-data-table tbody tr {
      & td {
        &:first-child {
          padding-left: 0;
          background-color: inherit;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    .v-data-footer__select {
      visibility: hidden;
    }
  }
}
</style>
