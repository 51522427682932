var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-banner',{attrs:{"sticky":"","color":"white"}},[_c('v-btn',{staticClass:"close-button",attrs:{"fab":"","dark":"","x-small":"","depressed":"","color":"grey","title":"Cancel"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"pa-0"},[_c('h1',{staticClass:"mb-0"},[(_vm.organisation.hubspot_id)?_c('a',{attrs:{"target":"_blank","href":("https://app.hubspot.com/contacts/5907605/company/" + (_vm.organisation.hubspot_id)),"title":("Hubspot Company ID: " + (_vm.organisation.hubspot_id))}},[_vm._v(_vm._s(_vm.organisation.name))]):_c('span',[_vm._v(_vm._s(_vm.organisation.name))])])])],1),_c('v-card',{staticClass:"py-8",attrs:{"id":"organisation-details"}},[_c('v-card-text',[_c('v-card',{staticClass:"pa-4 mb-8"},[_c('h4',{staticClass:"mt-2"},[_c('fa',{attrs:{"icon":"user-alt"}}),_vm._v(" B2B Dashboard Owners")],1),_c('v-data-table',{attrs:{"dense":"","items-per-page":100,"footer-props":{ 'disable-items-per-page': true },"sort-by":"name","must-sort":true,"headers":_vm.organisation.users.headers,"items":_vm.organisation.users.items,"header-props":{
            sortIcon: 'mdi-menu-down',
          },"no-data-text":"No B2B Dashboard Owners found."},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted_created)+" ")]}},{key:"item.renewal_date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted_renewal_date)+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('es-btn',{staticClass:"small",attrs:{"disabled":_vm.busy,"color":item.active ? 'orange' : 'indigo'},on:{"click":function($event){return _vm.changeAdminAccountStatus(item)}}},[_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(((item.active ? 'Deactivate' : 'Reactivate') + " account")))])])]}}],null,true)})],1),_c('v-card',{staticClass:"pa-4 mb-8"},[_c('h4',{staticClass:"mt-2"},[_c('fa',{attrs:{"icon":"ticket-alt"}}),_vm._v(" Connect codes")],1),_c('v-data-table',{attrs:{"dense":"","items-per-page":100,"footer-props":{ 'disable-items-per-page': true },"sort-by":"code","must-sort":true,"headers":_vm.organisation.connectCodes.headers,"items":_vm.organisation.connectCodes.items,"header-props":{
            sortIcon: 'mdi-menu-down',
          },"no-data-text":"No connect codes found."},scopedSlots:_vm._u([{key:"item.validFrom",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.formattedValidFrom)+" ")]}},{key:"item.validTo",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.formattedValidTo)+" ")]}}],null,true)})],1),_c('v-card',{staticClass:"pa-4 mb-8"},[_c('h4',{staticClass:"mt-2"},[_c('fa',{attrs:{"icon":"ticket-alt"}}),_vm._v(" Certificate vouchers")],1),_c('v-data-table',{attrs:{"dense":"","items-per-page":100,"footer-props":{ 'disable-items-per-page': true },"sort-by":"code","must-sort":true,"headers":_vm.organisation.certificateVouchers.headers,"items":_vm.organisation.certificateVouchers.items,"header-props":{
            sortIcon: 'mdi-menu-down',
          },"no-data-text":"No certificate vouchers found."},scopedSlots:_vm._u([{key:"item.validFrom",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.formattedValidFrom)+" ")]}},{key:"item.validTo",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.formattedValidTo)+" ")]}}],null,true)})],1),_c('v-card',{staticClass:"pa-4 mb-8"},[_c('h4',{staticClass:"mt-2"},[_c('fa',{attrs:{"icon":"ticket-alt"}}),_vm._v(" Certificate credits")],1),_c('v-data-table',{attrs:{"dense":"","items-per-page":100,"footer-props":{ 'disable-items-per-page': true },"sort-by":"code","must-sort":true,"headers":_vm.organisation.certificateCredits.headers,"items":_vm.organisation.certificateCredits.items,"header-props":{
            sortIcon: 'mdi-menu-down',
          },"no-data-text":"No certificate credits found."},scopedSlots:_vm._u([{key:"item.validFrom",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.formattedValidFrom)+" ")]}},{key:"item.validTo",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.formattedValidTo)+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }