var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('OrganisationDetails'),_c('OrganisationStatement'),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Filter","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('OrganisationsForm'),_c('div',[_c('es-btn',{attrs:{"color":"indigo"},on:{"click":_vm.refresh}},[_c('fa',{attrs:{"icon":"sync"}}),_vm._v(" Refresh")],1),_c('es-btn',{attrs:{"color":"orange"},on:{"click":_vm.diffToggleDialog}},[_c('fa',{attrs:{"icon":"magic"}}),_vm._v(" Check Org names")],1)],1)],1),_c('v-card',{staticClass:"mb-6"},[_c('v-data-table',{attrs:{"fixed-header":"","height":"70vh","sort-by":"name","loading-text":"Loading organisations ...","no-data-text":"No organisations found.","must-sort":true,"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"items-per-page":100,"loading":_vm.busy,"header-props":{ sortIcon: 'mdi-menu-down' },"footer-props":{ 'disable-items-per-page': true }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.hubspot_id)?_c('a',{attrs:{"target":"_blank","href":("https://app.hubspot.com/contacts/5907605/company/" + (item.hubspot_id)),"title":("Hubspot Company ID: " + (item.hubspot_id))}},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.initial_dashboard_user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('es-btn',{staticClass:"es-btn mr-2",attrs:{"title":item.initial_dashboard_user.active
                ? ("Impersonate " + (item.initial_dashboard_user.username) + " in the B2B Dashboard")
                : 'This user is not active',"disabled":_vm.busy ||
                !item.initial_dashboard_user.user_profile_id ||
                !item.initial_dashboard_user.active},on:{"click":function($event){$event.stopPropagation();return _vm.impersonate(item.initial_dashboard_user.user_profile_id)}}},[_c('fa',{attrs:{"icon":"user-alt"}})],1),_c('div',[_vm._v(" "+_vm._s(item.initial_dashboard_user.username)),_c('br'),_c('small',[_vm._v(_vm._s(item.initial_dashboard_user.email))])])],1)]}},{key:"item.most_recently_created_connect_code",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted_most_recently_created_connect_code)+" ")]}},{key:"item.most_recently_used_connect_code",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted_most_recently_used_connect_code)+" ")]}},{key:"item.most_recent_certificate_purchased",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted_most_recent_certificate_purchased)+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"actions d-flex"},[_c('es-btn',{staticClass:"es-btn mb-1 ml-auto white--text",attrs:{"title":("View statement for " + (item.name)),"color":"secondary","disabled":_vm.busy},on:{"click":function($event){$event.stopPropagation();return _vm.viewStatement(item.organisation_id)}}},[_c('fa',{attrs:{"icon":"clipboard-list"}})],1),_c('es-btn',{staticClass:"es-btn white--text",attrs:{"title":("View details for " + (item.name)),"color":"secondary","disabled":_vm.busy},on:{"click":function($event){$event.stopPropagation();return _vm.open(item.organisation_id)}}},[_c('fa',{attrs:{"icon":"dice-d6"}}),_vm._v(" Details")],1),_c('es-btn',{staticClass:"es-btn",attrs:{"title":"Copy Organisation ID to clipboard","color":"primary"},on:{"click":function($event){return _vm.copyToClipboard({
                label: 'Organisation ID',
                text: item.organisation_id
              })}}},[_c('fa',{attrs:{"icon":"copy"}})],1),_c('es-btn',{staticClass:"es-btn",attrs:{"title":"Copy HubSpot ID to clipboard","color":"orange"},on:{"click":function($event){return _vm.copyToClipboard({ label: 'HubSpot ID', text: item.hubspot_id })}}},[_c('fa',{attrs:{"icon":"copy"}})],1)],1)]}}],null,true)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.busy),expression:"busy"}],staticClass:"dot-windmill",attrs:{"slot":"progress"},slot:"progress"})])],1),_c('v-dialog',{model:{value:(_vm.diffDialogActive),callback:function ($$v) {_vm.diffDialogActive=$$v},expression:"diffDialogActive"}},[_c('v-card',{staticClass:"pa-2",attrs:{"title":"Organisation name differences"}},[_c('v-card-actions',[_c('h4',{staticClass:"ma-0"},[_vm._v("Organisations with different names in HubSpot")]),_c('v-spacer'),_c('v-btn',{staticClass:"close-button",attrs:{"fab":"","dark":"","x-small":"","depressed":"","color":"grey"},on:{"click":_vm.diffToggleDialog}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{attrs:{"dense":"","items-per-page":100,"footer-props":{ 'disable-items-per-page': true },"sort-by":"code","must-sort":true,"fixed-header":"","height":"70vh","headers":_vm.diffHeaders,"items":_vm.diffItems,"header-props":{
          sortIcon: 'mdi-menu-down'
        },"loading":_vm.diffBusy,"sticky-footer":true,"no-data-text":"No found."},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('es-btn',{staticClass:"es-btn white--text",class:{ 'opacity-50': item.isBusy },attrs:{"title":("View details for " + (item.name)),"color":"secondary","disabled":item.isBusy},on:{"click":function($event){$event.stopPropagation();return _vm.diffUpdateItem(item)}}},[_c('fa',{attrs:{"icon":"dice-d6"}}),_vm._v(" Update name")],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }