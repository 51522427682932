<template>
    <v-dialog v-model="dialog">
        <v-banner sticky color="white">
            <v-btn class="close-button" fab dark x-small depressed color="grey" @click="close" title="Cancel"><v-icon
                    dark>mdi-close</v-icon></v-btn>
            <v-card-text class="organisation-banner">
                <h1>{{ OrganisationName }}</h1>
            </v-card-text>
        </v-banner>
        <v-card class="py-8" id="organisation-statement">
            <tableau-viz ref="tableauViz" id="tableau-viz" class="justify-center"
                src="https://dub01.online.tableau.com/t/bc-englishscore-ltd/views/AccountStatementsJune2023version-Parameterised/Scoresbysittingstartdate"
                width="1300" height="620" toolbar="side" :token="tableauToken">
            </tableau-viz>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from "vuex";

export default {
    data: function () {
        return {
            OrganisationName: ""
        }
    },
    computed: {
        ...mapState("organisation", ["showOrganisationStatementModal", "rawOrganisations", "tableauToken"]),
        dialog: {
            get() { return this.showOrganisationStatementModal },
            set(value) { this.$store.commit("organisation/setShowOrganisationStatementModal", value) },
        },
    },
    methods: {
        close() {
            this.dialog = false;
        },
    },
    watch: {
        dialog(value) {
            if (!value) {
                this.$store.commit("organisation/setShowOrganisationStatementModal", false);
                this.$store.dispatch("organisation/resetOrganisationState");
                if (this.$route.params.uuid) this.$router.push({ name: "Organisations", params: {}, query: this.$route.query })
            }
        },
        $route: {
            handler() {
                if (this.$route.name === 'Organisations-Statement' && this.$route.params.uuid) {
                    this.$store.dispatch("organisation/loadOrganisationStatement", this.$route.params.uuid);
                    this.OrganisationName = this.rawOrganisations.find(organisation => organisation.organisation_id === this.$route.params.uuid).name
                }
                else {
                    this.close()
                }
            },
            immediate: true
        },
    },
    created() {
        let tableauScript = document.createElement('script')
        tableauScript.setAttribute('type', 'module')
        tableauScript.setAttribute('src', 'https://dub01.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js')
        document.head.appendChild(tableauScript)
    }
};
</script>
  
<style lang='scss' scoped>
.close-button {
    position: absolute;
    top: 36px;
    right: 22px;
    z-index: 1;
}

.organisation-banner {
    text-align: center;
    color: white;
    background-color: #2A0355;
    padding-top: 4%;
}
</style>
  
